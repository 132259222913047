var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"px-0 py-0"},[_c('div',{staticClass:"messageBackground",style:(_vm.isChecked
        ? 'border-radius: 1px;border-top: 1px solid #EEE;background: #E7EDF6;box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);'
        : ''),on:{"mouseenter":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-checkbox',{staticStyle:{"margin-right":"20px"},attrs:{"color":"#144FA9"},model:{value:(_vm.isChecked),callback:function ($$v) {_vm.isChecked=$$v},expression:"isChecked"}}),_c('v-row',{staticStyle:{"cursor":"pointer","user-select":"none"},attrs:{"no-gutters":"","align":"center"},on:{"click":function($event){return _vm.$emit('showDetailPage', _vm.message)}}},[(_vm.message.status.name == 'Нові')?_c('div',{staticClass:"statusBox"},[_vm._v("Нове")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.message?.name))]),_c('span',{staticStyle:{"font-weight":"600","margin-right":"10px"},style:(_vm.message.type == 'Reserve'
              ? 'margin-left: 20px;'
              : 'margin-left: 0px;')},[_vm._v(_vm._s(_vm.message.type == "Reserve" ? "Оренда автобуса" : _vm.message.type == "Callback" ? "Передзвоніть мені" : "Скасування квитка"))]),(_vm.message.type == 'Reserve')?_c('span',[_vm._v("Ім’я: "+_vm._s(_vm.message.name)+". Email: igorhertsyuk89@gmail.com. Номер телефону: "+_vm._s(_vm.message.phone_number)+". ")]):(_vm.message.type == 'Order')?_c('span',[_vm._v("Маршурт: "),_c('b',[_vm._v(" "+_vm._s(_vm.message?.order?.schedule?.route?.departure.translations.find( (translate) => translate.lang == "ua" ).name)+" - "+_vm._s(_vm.message?.order?.schedule?.route?.destination.translations.find( (translate) => translate.lang == "ua" ).name)+" ")]),_vm._v(" . Ім’я: "+_vm._s(_vm.message?.user?.first_name)+" "+_vm._s(_vm.message?.user?.last_name)+". Email: "+_vm._s(_vm.message?.user?.email)+". ")]):_c('span',[_vm._v("Номер телефону: "+_vm._s(_vm.message.phone_number)+". ")])]),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(!_vm.isHover)?_c('span',[_vm._v(" "+_vm._s(new Date(_vm.message.created_at).toLocaleTimeString("uk-UA", { minute: "2-digit", hour: "2-digit", }))+" ")]):_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('div',{staticClass:"deleteIcon",staticStyle:{"margin-right":"28px","cursor":"pointer"},on:{"click":function($event){return _vm.$emit('deleteMessage', _vm.message.id)}}}),(
              _vm.message &&
              _vm.message.status &&
              _vm.message?.status?.name !== 'Архівовані'
            )?_c('div',{staticClass:"archiveIcon",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('changeMessageStatus', _vm.message.id, 'Архівовані')}}}):_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"color":"#4B5262"},on:{"click":function($event){return _vm.$emit('changeMessageStatus', _vm.message.id, 'Прочитані')}}},[_vm._v("mdi-restore")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }