<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin: 12px 0px">
      <back-btn label="Назад" @click="$emit('back')" />
      <v-row no-gutters justify="end">
        <div class="iconBackground" style="margin-right: 12px">
          <div class="deleteIcon" @click="$emit('deleteMessage', message.id)" />
        </div>
        <div class="iconBackground" v-if="message.status.name !== 'Архівовані'">
          <div
            class="archiveIcon"
            @click="$emit('changeMessageStatus', message.id, 'Архівовані')"
          />
        </div>
        <v-icon
          color="#4B5262"
          v-else
          style="cursor: pointer"
          @click="$emit('changeMessageStatus', message.id, 'Прочитані')"
          >mdi-restore</v-icon
        >
      </v-row>
    </v-row>
    <div class="sectionBackground">
      <v-row no-gutters>
        <p class="sectionTitle">
          {{
            message?.type == "Reserve"
              ? "Оренда автобуса"
              : message?.type == "Callback"
              ? "Передзвоніть мені"
              : "Повернення квитка"
          }}
        </p>
        <v-row no-gutters justify="end">
          <span style="color: #989898">
            {{
              new Date(message.created_at).toLocaleDateString("uk-UA", {
                year: "numeric",
                month: "short",
                day: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}
            {{
              new Date(message.created_at).toLocaleTimeString("uk-UA", {
                minute: "2-digit",
                hour: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}
          </span>
        </v-row>
      </v-row>

      <div class="sectionLine" style="margin: 10px 0px 20px 0px" />
      <v-row no-gutters align="center">
        <img
          src="@/assets/img/userAvatar.png"
          width="40px"
          height="40px"
          alt="avatar"
          style="margin-right: 12px"
        />
        <span class="messageSectionText"
          >Від:
          <a
            :href="`mailto: ${message?.user?.email}`"
            style="color: #144fa9; text-decoration: none"
            >{{ message?.user?.email }}</a
          ></span
        >
      </v-row>
      <p class="sectionName" style="margin: 20px 0px">
        {{
          message.type == "Reserve"
            ? "Дані з форми замовлення перевезень"
            : message.type == "Callback"
            ? "Дані з форми замовлення дзвінка"
            : "Дані з форми скасування квитка"
        }}
      </p>
      <v-col class="px-0 py-0" v-if="message.type !== 'Order'">
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 12px"
          v-if="message.name"
        >
          <span class="sectionName">Ім’я:</span>
          <p class="messageSectionText">{{ message?.name }}</p>
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Телефон:</span>
          <span class="messageSectionText">{{ message?.phone_number }}</span>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 12px"
          v-if="message.departure"
        >
          <span class="sectionName">Пункт відправлення:</span>
          <span class="messageSectionText">{{ message?.departure }}</span>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 12px"
          v-if="message.person_count"
        >
          <span class="sectionName">Кількість персон:</span>
          <span class="messageSectionText">{{ message?.person_count }}</span>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 12px"
          v-if="message.day_count"
        >
          <span class="sectionName">Кількість днів:</span>
          <span class="messageSectionText">{{ message?.day_count }}</span>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 12px"
          v-if="message.company_name"
        >
          <span class="sectionName">Назва компанії:</span>
          <span class="messageSectionText">{{ message?.company_name }}</span>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 12px"
          v-if="message.route"
        >
          <span class="sectionName">Маршрут:</span>
          <span class="messageSectionText">{{ message?.route }}</span>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 12px"
          v-if="message.message"
        >
          <span class="sectionName">Додатково:</span>
          <span class="messageSectionText">{{ message?.message }}</span>
        </v-row>
      </v-col>
      <v-col v-else>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Ім’я:</span>
          <p class="messageSectionText">
            {{ message?.user?.first_name }} {{ message?.user?.last_name }}
          </p>
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Email:</span>
          <span class="messageSectionText">{{ message?.user?.email }}</span>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 12px"
          v-if="message.user.phone_number !== 'undefined'"
        >
          <span class="sectionName">Номер телефону:</span>
          <span class="messageSectionText">{{
            message?.user?.phone_number
          }}</span>
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Маршрут:</span>
          <span class="messageSectionText"
            >{{
              message?.order?.schedule?.route?.departure.translations.find(
                (translate) => translate.lang == "ua"
              )?.name
            }}
            -
            {{
              message?.order?.schedule?.route?.destination.translations.find(
                (translate) => translate.lang == "ua"
              )?.name
            }}</span
          >
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Пункт відправлення:</span>
          <span class="messageSectionText">{{
            message?.order?.departure?.translations.find(
              (translate) => translate.lang == "ua"
            ).name
          }}</span>
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Пункт прибуття:</span>
          <span class="messageSectionText">{{
            message?.order?.destination?.translations.find(
              (translate) => translate.lang == "ua"
            ).name
          }}</span>
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Кількість квитків:</span>
          <span class="messageSectionText">{{
            message?.order?.seats_count
          }}</span>
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Дата відправлення:</span>
          <span class="messageSectionText">
            {{
              new Date(
                addMinutesToDate(
                  message?.order?.schedule?.departed_at,
                  message?.order?.schedule.route?.cities.find(
                    (city) =>
                      city.station.city_uuid == message?.order?.departure?.id
                  ).time_from_start
                )
              ).toLocaleDateString("uk-UA", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
            }}</span
          >
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Автобус:</span>
          <span class="messageSectionText"
            >{{ message?.order?.bus?.manufacturer }}
            {{ message?.order?.bus?.model }} ({{
              message?.order?.bus?.government_number
            }})</span
          >
        </v-row>
        <v-row no-gutters align="center" style="margin-bottom: 12px">
          <span class="sectionName">Дата купівлі:</span>
          <span class="messageSectionText">{{
            new Date(message?.order?.paid_at).toLocaleDateString("uk-UA", {
              month: "2-digit",
              year: "numeric",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}</span>
        </v-row>
      </v-col>
      <a
        v-if="message.type !== 'Order'"
        :href="
          message.type == 'Callback'
            ? `tel: ${message.phone_number}`
            : `mailto: igorhertsyuk89@gmail.com`
        "
        style="color: #144fa9; text-decoration: none"
      >
        <submit-button
          style="
            height: 48px !important;
            font-size: 20px;
            font-weight: 700;
            margin-top: 40px;
          "
          >Відповісти</submit-button
        ></a
      >
      <v-row
        v-else-if="
          new Date(message?.order?.schedule?.departed_at?.replace(' ', 'T')).getTime() >
          new Date().getTime()
        "
        no-gutters
        align="center"
        justify="center"
      >
        <v-col
          cols="6"
          style="padding-right: 10px"
          v-if="
            message.order.status !== 'Payed' &&
            message.order.status !== 'Refunded'
          "
        >
          <v-btn
            width="100%"
            color="green"
            outlined
            dense
            height="48px"
            style="border-radius: 10px; text-transform: none"
            :loading="isLoadingBtn"
            @click="changeOrderStatus('Payed')"
            >Користувач вирішив не скасовувати квиток</v-btn
          >
        </v-col>
        <v-col cols="6" v-if="message.order.status == 'Payed'">
          <v-btn
            width="100%"
            color="green"
            outlined
            dense
            height="48px"
            style="
              border-radius: 10px;
              text-transform: none;
              pointer-events: none;
            "
            >Користувач вирішив не скасовувати квиток
            <v-icon size="20px" style="margin-left: 8px">mdi-check</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          style="padding-left: 10px"
          v-if="
            message.order.status !== 'Payed' &&
            message.order.status !== 'Refunded'
          "
        >
          <v-btn
            width="100%"
            color="#DF3A3A"
            outlined
            dense
            height="48px"
            style="border-radius: 10px; text-transform: none"
            :loading="isLoadingBtnCanceled"
            @click="changeOrderStatus('Refunded')"
            >Користувачу було повернено кошти</v-btn
          >
        </v-col>
        <v-col cols="6" v-if="message.order.status == 'Refunded'">
          <v-btn
            width="100%"
            color="#DF3A3A"
            outlined
            dense
            height="48px"
            style="
              border-radius: 10px;
              text-transform: none;
              pointer-events: none;
            "
            >Користувачу було повернено кошти
            <v-icon size="20px">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters v-else justify="center">
        <v-col class="px-0 py-0" cols="8">
          <v-btn
            width="100%"
            color="red"
            outlined
            dense
            height="48px"
            style="
              border-radius: 10px;
              text-transform: none;
              pointer-events: none;
            "
            :loading="isLoadingBtn"
            @click="changeOrderStatus('Payed')"
            >Скасування квитка неможливе, оскільки рейс вже відправився.</v-btn
          >
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <cancel-btn
          style="margin-top: 12px"
          text="Скасувати"
          @click="$emit('back')"
        />
      </v-row>
    </div>
  </v-col>
</template>

<script>
import diffrentTimeMixin from "../../../mixins/diffrentTimeMixin";
import bookingTicketService from "../../../requests/User/bookingTicketService";
import backBtn from "../../UI/Buttons/backBtn.vue";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
import SubmitButton from "../../UI/Buttons/submitButton.vue";
export default {
  components: { backBtn, SubmitButton, CancelBtn },
  mixins: [diffrentTimeMixin],
  data: () => ({
    isLoadingBtn: false,
    isLoadingBtnCanceled: false,
  }),
  props: {
    message: {
      require: true,
    },
  },
  methods: {
    async changeOrderStatus(status) {
      if (status == "Payed") {
        this.isLoadingBtn = true;
      } else {
        this.isLoadingBtnCanceled = true;
      }
      const form = new FormData();
      form.append("status", status);
      await bookingTicketService
        .changeOrderStatus(this.message?.order?.id, form)
        .then((res) => {
          if (res.status == "Success") {
            this.$emit("changeMessageStatus", this.message?.id, "Архівовані");
          }
        });
    },
  },
};
</script>

<style scoped>
.sectionName {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.messageSectionText {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 12px;
  margin-bottom: 0px;
}
</style>