<template>
  <v-col class="px-0 py-0">
    <div
      class="messageBackground"
      :style="
        isChecked
          ? 'border-radius: 1px;border-top: 1px solid #EEE;background: #E7EDF6;box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);'
          : ''
      "
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <v-row no-gutters align="center">
        <v-checkbox
          color="#144FA9"
          style="margin-right: 20px"
          v-model="isChecked"
        />
        <v-row
          no-gutters
          align="center"
          style="cursor: pointer; user-select: none"
          @click="$emit('showDetailPage', message)"
        >
          <div class="statusBox" v-if="message.status.name == 'Нові'">Нове</div>
          <span>{{ message?.name }}</span>
          <span
            style="font-weight: 600; margin-right: 10px"
            :style="
              message.type == 'Reserve'
                ? 'margin-left: 20px;'
                : 'margin-left: 0px;'
            "
            >{{
              message.type == "Reserve"
                ? "Оренда автобуса"
                : message.type == "Callback"
                ? "Передзвоніть мені"
                : "Скасування квитка"
            }}</span
          >
          <span v-if="message.type == 'Reserve'"
            >Ім’я: {{ message.name }}. Email: igorhertsyuk89@gmail.com. Номер
            телефону: {{ message.phone_number }}.
          </span>
          <span v-else-if="message.type == 'Order'"
            >Маршурт:
            <b>
              {{
                message?.order?.schedule?.route?.departure.translations.find(
                  (translate) => translate.lang == "ua"
                ).name
              }}
              -
              {{
                message?.order?.schedule?.route?.destination.translations.find(
                  (translate) => translate.lang == "ua"
                ).name
              }}
            </b>

            . Ім’я: {{ message?.user?.first_name }}
            {{ message?.user?.last_name }}. Email: {{ message?.user?.email }}.
          </span>
          <span v-else>Номер телефону: {{ message.phone_number }}. </span>
        </v-row>

        <v-row no-gutters justify="end">
          <span v-if="!isHover">
            {{
              new Date(message.created_at).toLocaleTimeString("uk-UA", {
                minute: "2-digit",
                hour: "2-digit",
              })
            }}
          </span>
          <v-row no-gutters align="center" justify="end" v-else>
            <div
              class="deleteIcon"
              style="margin-right: 28px; cursor: pointer"
              @click="$emit('deleteMessage', message.id)"
            />
            <div
              v-if="
                message &&
                message.status &&
                message?.status?.name !== 'Архівовані'
              "
              class="archiveIcon"
              style="cursor: pointer"
              @click="$emit('changeMessageStatus', message.id, 'Архівовані')"
            />
            <v-icon
              color="#4B5262"
              v-else
              style="cursor: pointer"
              @click="$emit('changeMessageStatus', message.id, 'Прочитані')"
              >mdi-restore</v-icon
            >
          </v-row>
        </v-row>
      </v-row>
    </div>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    isChecked: false,
    isHover: false,
    showDetailPage: false,
  }),
  props: {
    message: {
      require: true,
    },
  },
  watch: {
    isChecked: {
      handler() {
        this.$emit("check");
      },
    },
  },
};
</script>

<style scoped>
.messageBackground {
  border-radius: 1px;
  border-top: 1px solid #eee;
  background: #fff;
  padding: 0px 16px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.statusBox {
  border-radius: 10px;
  background: #5c8cd6;
  padding: 4px 8px;
  color: #fafafa;
  font-family: "MacPaw Fixel";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: grid;
  place-items: center;
}
</style>