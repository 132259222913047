<template>
  <div>
    <v-expand-transition>
      <v-col class="px-0 py-0" v-if="!showDetailPage">
        <v-row no-gutters style="margin: 40px 0px 20px 0px">
          <div
            v-for="status in statusList"
            :key="status.id"
            class="statusBox"
            :class="activeStatus == status.id ? 'activeStatusBox' : ''"
            @click="activeStatus = status.id"
          >
            {{ status.name }}
          </div>
          <v-expand-x-transition>
            <v-row no-gutters justify="end" v-if="checkedMessages.length > 0">
              <div class="iconBackground" style="margin-right: 12px">
                <div class="deleteIcon" @click="deleteCheckedMessage" />
              </div>
              <div class="iconBackground">
                <div class="archiveIcon" @click="archiveCheckedMessage" />
              </div>
            </v-row>
          </v-expand-x-transition>
        </v-row>
        <loader v-if="showLoader" />
        <v-col v-else class="px-0 py-0">
          <message-item
            v-for="message in messageList"
            :key="message.id"
            @check="checkMessage(message.id)"
            @showDetailPage="showDetailMessage"
            @changeMessageStatus="changeMessageStatus"
            @deleteMessage="deleteMessage"
            :message="message"
          />
          <pagination
            :page="page"
            :quantityPage="quantityPage"
            @changePage="changePage"
          />
        </v-col>
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <message-detail-page
        v-if="showDetailPage"
        :message="detailMessage"
        @changeMessageStatus="changeMessageStatus"
        @deleteMessage="deleteMessage"
        @back="showDetailPage = false"
      />
    </v-expand-transition>
  </div>
</template>

<script>
import MessageDetailPage from "./messageDetailPage.vue";
import messageItem from "./messageItem.vue";
import messageService from "./../../../requests/Admin/messageService.js";
import Loader from "../../UI/Loader.vue";
import Pagination from "../../UI/pagination.vue";
export default {
  components: { messageItem, MessageDetailPage, Loader, Pagination },
  data: () => ({
    activeStatus: 0,
    checkedMessages: [],
    statusList: [],
    messageList: [],
    detailMessage: {},
    showDetailPage: false,
    showLoader: true,
    page: 1,
    quantityPage: 0,
    key: 0,
  }),
  mounted() {
    this.getMessagesStatus();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getMessages();
    },
    checkMessage(id) {
      let index = this.checkedMessages.findIndex((message) => message == id);
      if (index == -1) {
        this.checkedMessages.push(id);
      } else {
        this.checkedMessages = this.checkedMessages.filter(
          (message) => message !== id
        );
      }
    },
    async getMessagesStatus() {
      await messageService.getMessagesStatus().then((res) => {
        if (res.status == "Success") {
          this.statusList = res.data;
          this.statusList.unshift({
            id: 0,
            name: "Усі",
          });
        }
        this.getMessages();
      });
    },
    async getMessages() {
      await messageService
        .getMessages(this.page, this.activeStatus)
        .then((res) => {
          if (res.status == "Success") {
            this.messageList = res.data;
            this.quantityPage = res.pagination.meta.last_page;
            this.showLoader = false;
          }
        });
    },
    async changeMessageStatus(message_id, status_name) {
      let status_id = this.statusList.find(
        (status) => status.name == status_name
      )?.id;
      await messageService
        .changeMessageStatus(message_id, status_id)
        .then((res) => {
          if (res.status == "Success") {
            this.showLoader = true;
            if (status_name !== "Прочитані") {
              this.showDetailPage = false;
            }
            this.getMessages();
          }
        });
    },
    async deleteMessage(id) {
      await messageService.deleteMessage(id).then(() => {
        this.showLoader = true;
        this.getMessages();
      });
    },
    deleteCheckedMessage() {
      this.checkedMessages.forEach((message_id) => {
        this.deleteMessage(message_id);
      });
    },
    archiveCheckedMessage() {
      this.checkedMessages.forEach((message_id) => {
        this.changeMessageStatus(message_id, "Архівовані");
      });
    },
    showDetailMessage(message) {
      this.detailMessage = message;
      this.showDetailPage = true;
      if (message.status && message.status.name == "Нові") {
        this.changeMessageStatus(message.id, "Прочитані");
      }
    },
  },
  watch: {
    activeStatus: {
      handler() {
        this.showLoader = true;
        this.getMessages();
      },
    },
    showDetailPage: {
      handler() {
        this.$vuetify.goTo(0, 0);
      },
    },
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>