import requestService from "../requestService";
import store from '@/store';

export default {
    async getMessagesStatus() {
        const response = await requestService.get(`/cp_dash/status`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getMessages(page, status) {
        const response = await requestService.get(`/cp_dash/message${status ? `/status/${status}` : ''}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async uploadMessage(form) {
        const response = await requestService.post(`/cp_dash/message`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async changeMessageStatus(message_id, status_id) {
        const response = await requestService.post(`/cp_dash/message/status/${message_id}/${status_id}`,{}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async deleteMessage(id) {
        const response = await requestService.delete(`/cp_dash/message/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },

}